<template>
  <v-card
    color="grey lighten-1"
    class="mb-12"
    min-height="150px"
    style="padding-top: 20px;"
  >
    <v-alert
      v-if="!allowReservations"
      outlined
      type="warning"
      text
      style="margin: 0 20px;"
    >
        {{$t('booking.formFieldsAllowReservationsMessage', locale)}}
    </v-alert>
    <form-fields
      v-if="!loading"
      :locale="locale"
      :onChange="handleChangeIsModified"
      :formFields="formFields"
      :pac="formFields.paxAsCount"
    />
  </v-card>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import FormFields from '@/components/formFields/Index'
export default {
  components: {
    FormFields,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    allowReservations: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: true,
    formFields: null,
  }),
  mounted () {
    this.handleGetFormFieldsDefault ()
  },
  methods: {
    getTranslation (v, locale) {
      return utils.getTranslation(v, locale)
    },
    handleGetFormFieldsDefault () {
      api.getItem('booking', 'v1/system/workspaces/', this.workspaceID)
        .then (response => {
          this.formFields = response && response.FormFieldsDefault ? JSON.parse(response.FormFieldsDefault) : null
          this.loading = false
        })
    },
    handleChangeIsModified (v) {
      this.onChange(v)
    },
  },
}
</script>

